import React, { useContext } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { getTimeRemaining } from "src/utils/dates";
import Teaser from "./native";
import Recording from "./recording";
import EmbedPlayer from "./embed";
import { LeadersContext } from "../leaders";
import { VidContainer } from "./styled";

const SHOW_LIVE_TIME = 3;

function Player({ currentEvent }) {
  const intl = useIntl();
  const { currentLeader, leaderEvents } = useContext(LeadersContext);
  const timeRemaining = getTimeRemaining(currentEvent.time);
  const hoursRemaining = timeRemaining.total / 60 / 60 / 1000;
  const recordings = currentEvent.recording || {};

  // The recording is there and it has an http property
  const eventHasRecordings = Object.values(recordings).some(
    (recording) =>
      recording !== null &&
      recording !== "null" &&
      recording.indexOf("http") !== -1
  );

  // Show the event recording if we have recordings and the event status is "Ended"
  const showRecording = eventHasRecordings && currentEvent.status === "Ended";

  // Show the Leader Recording if showRecording is true, this is a leaders event
  // and a currentLeader has been selected.
  const showLeaderRecording =
    currentEvent.status === "Ended" &&
    leaderEvents.includes(currentEvent.id) &&
    currentLeader !== null;

  // Show the live if showRecording is false and hours reminaing is beneath
  // threshold or curentEvent.status is live
  const showLive =
    !showRecording &&
    (hoursRemaining < SHOW_LIVE_TIME || currentEvent.status === "Live") &&
    currentEvent.status !== "Ended";

  if (showLeaderRecording) {
    return <Recording recordings={currentLeader.recording} />;
  }

  if (showRecording) {
    return <Recording recordings={recordings} />;
  }

  if (showLive) {
    return (
      <VidContainer>
        <EmbedPlayer
          src={intl.formatMessage({ id: "gameday.player.live.url" })}
        />
      </VidContainer>
    );
  }

  return (
    <Teaser
      url={intl.formatMessage({ id: "teaser.home.video.src" })}
      poster={intl.formatMessage({ id: "teaser.home.video.poster" })}
    />
  );
}

export default Player;
