import React, { useState, useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import EmbedPlayer from "./embed";
import { Container, VidContainer } from "./styled";
import { color } from "src/styled/variables";

const { ilo_blue, ilo_dark_blue, ilo_grey } = color;

const Toggle = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0 0;
  font-size: 0.9rem;
  color: ${ilo_dark_blue};
`;

const ToggleLabel = styled.span`
  opacity: 0.8;
`;

const LanguageList = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 0 0 0 1rem;
  padding: 0;
`;

const Language = styled.li`
  padding: 0 4px;
  margin: 0;
  min-width: 80px;
  cursor: pointer;
  font-size: 0.79rem;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  color: ${({ selected }) => (selected ? ilo_dark_blue : ilo_blue)};
  background-color: ${({ selected }) => (selected ? ilo_grey : "#fff")};
  border: 1px solid ${ilo_grey};
  border-right: none;
  text-align: center;

  &:last-child {
    border-right: 1px solid ${ilo_grey};
  }
`;

const model = {
  recording_en: { index: 1, language: "English" },
  recording_fr: { index: 2, language: "français" },
  recording_es: { index: 3, language: "español" },
  recording_de: { index: 4, language: "Deutsch" },
  recording_ar: { index: 5, language: "عربى" },
  recording_zh: { index: 6, language: "中文" },
  recording_ru: { index: 7, language: "русский" },
  recording_floor: { index: 8, language: "Floor" },
};

function Recording({ recordings = {} }) {
  const intl = useIntl();
  const [currentRecording, setRecording] = useState("");

  function handleRecording(url) {
    setRecording(url);
  }

  const recordingList = Object.keys(recordings).reduce(
    (accumulator, recording) => {
      if (
        recordings[recording] &&
        recordings[recording].indexOf("http") !== -1
      ) {
        const rec = {
          language: model[recording].language,
          url: recordings[recording],
          code: recording.split("_")[1],
          index: model[recording].index,
        };
        accumulator.push(rec);
      }
      return accumulator;
    },
    []
  );

  recordingList.sort((a, b) => a.index - b.index);

  useEffect(() => {
    const defaultRecording = recordingList.find(
      (recording) => recording.code === intl.locale
    );
    if (defaultRecording) {
      return handleRecording(defaultRecording.url);
    }
    return handleRecording(recordingList[0].url);
  }, [recordings]); //eslint-disable-line

  const renderLanguageToggle = () => {
    return (
      <Toggle>
        <ToggleLabel>
          {intl.formatMessage({ id: "gameday.player.language" })}:{" "}
        </ToggleLabel>
        <LanguageList>
          {recordingList.map(({ url, language }) => (
            <Language
              key={language}
              selected={currentRecording === url}
              onClick={() => handleRecording(url)}
            >
              {language}
            </Language>
          ))}
        </LanguageList>
      </Toggle>
    );
  };

  return (
    <Container>
      <VidContainer>
        <EmbedPlayer src={currentRecording} />
      </VidContainer>
      {renderLanguageToggle()}
    </Container>
  );
}

export default Recording;
