import React from "react";
import { Iframe } from "./styled";

const EmbedPlayer = ({ src }) => (
  <Iframe
    webkitAllowFullScreen
    mozallowfullscreen
    allowFullScreen
    src={src}
    title="0"
    byline="0"
    portrait="0"
    frameborder="0"
    scrolling="no"
    allow="autoplay"
  ></Iframe>
);

export default EmbedPlayer;
