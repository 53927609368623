import React, { useState, useEffect } from "react";
import { getTimeRemaining } from "src/utils/dates";
import styled from "styled-components";
import { color } from "src/styled/variables";
import { useIntl } from "gatsby-plugin-intl";

const { ilo_pink, ilo_alert_red, ilo_light_turquoise } = color;

const Badge = styled.div`
  padding: 0 4px;
  background-color: ${({ color }) => color};
  display: inline-block;
  font-size: 0.78rem;

  .countdown {
    font-weight: 700;
  }
`;

function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

function Countdown({ date, duration, status }) {
  const intl = useIntl();
  const [timeRemaining, setTimeRemaining] = useState({
    total: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    function tick() {
      const time = getTimeRemaining(date);
      setTimeRemaining(time);
    }

    tick();
    const timeInterval = setInterval(() => {
      tick();
    }, 1000);
    return () => clearInterval(timeInterval);
  }, [date]);

  const renderCount = () => (
    <span className="countdown">
      {`${zeroPad(timeRemaining.hours, 2)}:${zeroPad(
        timeRemaining.minutes,
        2
      )}:${zeroPad(timeRemaining.seconds, 2)}`}
    </span>
  );

  const showCountDown = timeRemaining.days === 0 && timeRemaining.total > 0;

  const showLive =
    timeRemaining.total < 0 &&
    timeRemaining.total * -1 < duration * 60 * 60 * 1000;

  const showEnded = timeRemaining.total < 0 && status === "Ended";

  if (showCountDown) {
    return (
      <Badge color={ilo_pink}>
        <span> {intl.formatMessage({ id: "gameday.status.upcoming" })}: </span>
        {renderCount()}
      </Badge>
    );
  }

  if (showLive) {
    return (
      <Badge color={ilo_alert_red}>
        <span style={{ margin: "0 5px", color: "#fff", fontWeight: "600" }}>
          {intl.formatMessage({ id: "gameday.status.live" })}
        </span>
      </Badge>
    );
  }

  if (showEnded) {
    return (
      <Badge color={ilo_light_turquoise}>
        <span style={{ margin: "0 5px", fontWeight: "600" }}>
          {intl.formatMessage({ id: "gameday.status.ended" })}
        </span>
      </Badge>
    );
  }

  return null;
}

export default Countdown;
