import React from "react";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import { getTime } from "src/utils/dates";
import { color } from "src/styled/variables";
import clock from "src/images/icons/clock-icon.svg";
import Countdown from "src/components/countdown";
import media from "src/styled/responsive";

const { ilo_blue, ilo_dark_blue } = color;

const StyledSection = styled.section`
  width: 100%;

  ${media.sm`
    padding: 0 2rem;
  `}

  ${media.xs`
    padding: 0;
  `}

  h2 {
    color: ${ilo_blue};
    margin: 1rem 0 2rem;

    ${media.sm`
      margin: 1rem 0 0.5rem;
    `}

    ${media.xs`
      font-size: 1.5rem;
    `}
  }
`;

const PlayerHeader = styled.div`
  border-bottom: 1px solid ${ilo_dark_blue};
  padding: 0 0 1rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const EventTimesWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 50%;

  ${media.sm`
    width: 100%;
    justify-content: space-between;
  `}
`;

const EventTimes = styled.div`
  font-weight: 700;
  color: ${ilo_dark_blue};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 1rem 0 0;

  &:after {
    content: "*";
  }

  ${media.xs`
    font-size: 0.9rem;

    &:after {
      content: '';
    }
  `}

  &:before {
    content: "";
    background-image: url(${clock});
    background-size: contain;
    background-repeat: no-repeat;
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    margin: 0 0.5rem 0 0;

    ${media.xs`
      width: 1rem;
      height: 1rem;
    `}
  }
`;

const TimesNote = styled.div`
  font-size: 0.9rem;
  flex: 1 1 50%;
  text-align: right;

  ${media.sm`
    display: none;
  `}
`;

function PlayerDetails({
  currentEvent: { time, duration, titleEn, titleFr, titleEs, status },
}) {
  const intl = useIntl();

  const title = {
    en: titleEn,
    es: titleEs,
    fr: titleFr,
  };

  const renderTimes = (time, duration, currentLanguage, status) => {
    const times = getTime(time, duration);
    return <span>{`${times[0]} - ${times[1]}`}</span>;
  };

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <StyledSection>
          <PlayerHeader>
            <EventTimesWrapper>
              <EventTimes>
                {renderTimes(time, duration, currentLocale)}
              </EventTimes>
              <Countdown duration={duration} status={status} date={time} />
            </EventTimesWrapper>
            <TimesNote>
              {intl.formatMessage({ id: "programme.localTimes" })}
            </TimesNote>
          </PlayerHeader>
          <h2>{title[currentLocale]}</h2>
        </StyledSection>
      )}
    </IntlContextConsumer>
  );
}

export default PlayerDetails;
