import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import { color } from "src/styled/variables";
import media from "src/styled/responsive";

const { ilo_dark_blue } = color;

const StyledSection = styled.section`
  padding: 0 0 0 2rem;

  ${media.sm`
    display: none;
  `}

  h3 {
    color: ${ilo_dark_blue};
  }
`;

const SectionTitle = styled.div``;

const DocsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 0 1rem;
`;

const Doc = styled.li`
  margin: 0.5rem 0;
  padding: 0;
`;

function KeyDocuments({ documents = [] }) {
  const intl = useIntl();

  const link = {
    en: "docLinkEn",
    fr: "docLinkFr",
    es: "docLinkEs",
  };

  return (
    documents.length > 0 && (
      <StyledSection>
        <SectionTitle>
          <h3>{intl.formatMessage({ id: "gameday.documents" })}</h3>
        </SectionTitle>
        <DocsList>
          {documents.map((doc) => (
            <Doc>
              <a
                href={doc[link[intl.locale]]}
                className="download"
                target="_blank"
                rel="noreferrer"
              >
                {doc.title}
              </a>
            </Doc>
          ))}
        </DocsList>
      </StyledSection>
    )
  );
}

export default KeyDocuments;
