import React, { useContext } from "react";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import { toDateString, groupEventsByDate } from "src/utils/dates";
import styled from "styled-components";
import { color } from "src/styled/variables";
import media from "src/styled/responsive";
import { LeadersContext } from "./leaders";

const { ilo_dark_blue, ilo_yellow } = color;

const SubSection = styled.section`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;

  ${media.xs`
    flex-flow: column;
    align-items: flex-start;
  `}
`;

const SubSelectList = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  ${media.xs`
    width: 100%;
  `}
`;

const SubSelectItem = styled.li`
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? ilo_yellow : /none/)};
  padding: 3px 5px;
  color: ${ilo_dark_blue};
  border: 1px solid ${ilo_yellow};
  border-right: none;
  margin: 0;
  min-width: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;

  ${media.xs`
    font-size: 0.8rem;
    min-width: 6rem;
    flex: 1 1 auto;
  `}

  &:last-child {
    border-right: 1px solid ${ilo_yellow};
  }
`;

const SubSelectLabel = styled.span`
  margin: 0 1rem 0 0;

  ${media.xs`
    font-size: 0.8rem;
    margin: 0 0 0.6rem;
  `}
`;

function SubSelector({ currentEvent, allEvents, updateEvent, children }) {
  const { leaderEvents } = useContext(LeadersContext);
  const eventDate = toDateString(currentEvent.time);
  const eventGroups = groupEventsByDate(allEvents);
  const intl = useIntl();

  function handleClick(path) {
    updateEvent(path);
  }

  const title = {
    en: "shortTitleEn",
    es: "shortTitleEs",
    fr: "shortTitleFr",
  };

  // The recording is there and it has an http property
  // Copied from the player, should share the logic
  // const eventHasRecordings =
  //   currentEvent.recording &&
  //   Object.values(currentEvent.recording).some(
  //     (recording) =>
  //       recording !== null &&
  //       recording !== "null" &&
  //       recording.indexOf("http") !== -1
  //   );

  // Show the child of this component (the Speakers Menu)
  // if it's a leaders event with recordings
  const showChildren =
    currentEvent.status === "Ended" && leaderEvents.includes(currentEvent.id);

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) =>
        showChildren ? (
          children
        ) : (
          <SubSection>
            <SubSelectLabel>
              {intl.formatMessage({
                id: "gameday.subselector.label",
              })}
              :
            </SubSelectLabel>
            <SubSelectList>
              {eventGroups[eventDate].map((event) => {
                return (
                  <SubSelectItem
                    selected={event.id === currentEvent.id}
                    key={event[title[currentLocale]]}
                    className="subselector-event"
                    onClick={() => handleClick(event.slug)}
                  >
                    {event[title[currentLocale]]}
                  </SubSelectItem>
                );
              })}
            </SubSelectList>
          </SubSection>
        )
      }
    </IntlContextConsumer>
  );
}

export default SubSelector;
