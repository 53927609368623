import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import styled from "styled-components";
import { getDate, toDateString } from "src/utils/dates";
import { color } from "src/styled/variables";
import { groupEventsByDate } from "src/utils/dates";
import media from "src/styled/responsive";

const { ilo_blue, ilo_dark_blue, ilo_grey } = color;

const Events = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  h6 {
    margin: 0 0 1rem;
    ${media.xs`
      ${({ side, language }) =>
        side === "right" && language !== "fr"
          ? `align-self: flex-start; margin: 0 0 1rem 1.1rem`
          : null}
    `}
  }

  ${media.xs`
    align-items: ${({ side }) =>
      side === "right" ? "flex-end" : "flex-start"};
    width: 100%;
  `}
`;

const Days = styled.div`
  border-top: 1px solid ${ilo_dark_blue};
  padding: 1rem 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  ${media.xs`
    min-width: 80%;
    justify-content: flex-start;
  `}
`;

const DayWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &:before {
    background-color: ${ilo_grey};
    content: "";
    width: 2px;
    display: inline-block;
    height: 65%;
    margin: 0 1.1rem;
    position: relative;
    top: 3px;

    ${media.xs`
      display: none;
    `}
  }

  &:first-child {
    &:before {
      content: none;
    }
  }

  ${media.xs`
    margin: 0 10px;
    &:first-child {
      margin: 0 10px 0 0;
    }
    &:last-child {
      margin: 0 0 0 10px;
    }
  `}
`;

const Day = styled.div`
  text-shadow: ${({ selected }) =>
    selected ? `-0.06ex 0 ${ilo_blue}, 0.06ex 0 ${ilo_blue}` : "none"};
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  ${media.xs`
    font-size: 0.9rem;
    margin: 0;
  `}

  &:hover {
    text-shadow: -0.06ex 0 ${ilo_blue}, 0.06ex 0 ${ilo_blue};
  }

  &:after {
    ${({ selected }) => (selected ? `content: "";` : `content: none;`)}
    position: absolute;
    top: 2.3rem;
    left: 50%;
    height: 6px;
    width: 100%;
    background-color: #ffc72c;
    right: 50%;
    transform: translate(-50%, -50%);

    ${media.xs`
      top: 1.6rem;
      height: 4px;
    `}
  }
`;

function DateSelector({
  events,
  currentEvent,
  updateEvent,
  title,
  side = "left",
}) {
  const intl = useIntl();

  function handleClick(path) {
    updateEvent(path);
  }

  const groupedEvents = groupEventsByDate(events);

  const eventsList = Object.keys(groupedEvents);
  eventsList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  return (
    <Events side={side} language={intl.locale}>
      <h6>{title}</h6>
      <Days>
        {eventsList.map((group) => (
          <DayWrapper key={group} language={intl.locale}>
            <Day
              selected={
                toDateString(currentEvent.time) ===
                toDateString(groupedEvents[group][0].time)
              }
              className="date-selector-typo"
              onClick={() => handleClick(groupedEvents[group][0].slug)}
            >
              {getDate(groupedEvents[group][0].time, intl.locale, {
                abbreviated: true,
              })}
            </Day>
          </DayWrapper>
        ))}
      </Days>
    </Events>
  );
}

export default DateSelector;
