import React from "react";
import PropTypes from "prop-types";
import { useIntl, navigate } from "gatsby-plugin-intl";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { color } from "../styled/variables";
import EventSpeakers from "./eventSpeakers";
import { StaticQuery, graphql } from "gatsby";
import media from "../styled/responsive";
import Button from "../components/button";

const { ilo_blue, ilo_dark_blue } = color;

const StyledSection = styled.div`
  background-color: ${ilo_blue};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  clip-path: polygon(0 3rem, 100% 0, 100% 100%, 0 100%);
  padding: 5rem 0 2rem 0;

  ${media.sm`
    padding: 4rem 0 3rem;
  `}

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: #fefefe;
  }
`;

const StyledContainer = styled.div`
  padding: 20px 0 0 0;
`;

const Speakers = ({ data, eventId, orderByRank }) => {
  const intl = useIntl();

  return (
    <StyledSection>
      <Grid>
        <h3 className="daySpeakerTitle">
          {intl.formatMessage({ id: "teaser.home.speakers.title" })}
        </h3>
        <Row between="xs">
          <Col xs={12}>
            <EventSpeakers
              data={data}
              skin="dark"
              eventId={eventId}
              gridTotalColumn={12}
              numOfColumn={4}
              orderByRank={orderByRank}
            />
          </Col>
        </Row>
        <Row center="xs">
          <Col>
            <StyledContainer>
              <Button
                skin="yellow"
                shape="polygon"
                outline={ilo_dark_blue}
                onClick={() => navigate("/speakers/")}
              >
                {intl.formatMessage({ id: "teaser.home.speakers.allspeakers" })}
              </Button>
            </StyledContainer>
          </Col>
        </Row>
      </Grid>
    </StyledSection>
  );
};

export default function DaySpeakers({ eventId, orderByRank }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allSpeakerJson {
            edges {
              node {
                name
                jobtitleEn
                jobtitleEs
                jobtitleFr
                thumb
                rank
              }
            }
          }
          allEventJson {
            nodes {
              id
              status
              titleEn
              titleEs
              titleFr
              time
              region
              speaker
              duration
            }
          }
        }
      `}
      render={(data) => <Speakers data={data} eventId={eventId} orderByRank={orderByRank} />}
    />
  );
}

Speakers.propTypes = {
  data: PropTypes.shape({
    allSpeakerJson: PropTypes.shape({
      edges: PropTypes.shape({
        node: PropTypes.shape({
          name: PropTypes.string.isRequired,
          jobtitleEn: PropTypes.string.isRequired,
          jobtitleEs: PropTypes.string.isRequired,
          jobtitleFr: PropTypes.string.isRequired,
          thumb: PropTypes.string,
          rank: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    allEventJson: PropTypes.shape({
      nodes: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        titleEn: PropTypes.string.isRequired,
        titleEs: PropTypes.string.isRequired,
        titleFr: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        speaker: PropTypes.array.isRequired,
        duration: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

// add logic for the button at the end
/* <Col lg={colWidth}>
            <StyledLastCell>
              <Button
                fullWidth={true}
                skin="yellow"
                shape="polygon"
                outline={ilo_dark_blue}
                onClick={() => navigate("/speakers")}
              >
                {intl.formatMessage({ id: "teaser.home.speakers.allspeakers" })}
              </Button>
            </StyledLastCell>
          </Col>
        </Row> */
