import React, { useContext } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { LeadersContext } from "./leaders";
import Select from "react-select";
import styled from "styled-components";
import { color } from "src/styled/variables";
import media from "src/styled/responsive";

const { ilo_yellow, ilo_paler_yellow, ilo_dark_blue } = color;

const MenuWrapper = styled.menu`
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 0.9rem;

  ${media.xs`
    flex-flow: column;
    align-items: flex-start;
  `}

  .react-select-container {
    flex: 0 1 300px;

    ${media.xs`
      flex: 1 1 auto;
      width: 100%;
      margin: 1rem 0 0;
  `}
  }
`;

const Label = styled.div`
  margin: 0 1rem 0 0;
  font-weight: 600;
`;

function SpeakersMenu() {
  const intl = useIntl();
  const { allLeaders, updateLeader, currentLeader } = useContext(
    LeadersContext
  );

  const options = allLeaders.map((leader) => ({
    label: leader.name,
    value: leader,
  }));

  function handleChange(leader) {
    if (leader) {
      return updateLeader(leader.value);
    }
    return updateLeader(null);
  }

  const setCurrentValue = () => {
    if (currentLeader) {
      return options.find((option) => option.value.id === currentLeader.id);
    }
    return null;
  };

  const styles = {
    control: (provided) => {
      const borderRadius = 0;
      const backgroundColor = "#fff";
      const border = "none !important";
      const boxShadow = "none";
      const cursor = "pointer";
      return {
        ...provided,
        borderRadius,
        backgroundColor,
        border,
        boxShadow,
        cursor,
      };
    },

    valueContainer: (provided) => {
      const border = `1px solid ${ilo_yellow}`;
      const borderRight = "none";
      const backgroundColor = ilo_paler_yellow;
      const color = ilo_dark_blue;
      return { ...provided, border, borderRight, backgroundColor, color };
    },
    indicatorSeparator: () => {
      const display = "none";
      return { display };
    },
    dropdownIndicator: (provided) => {
      const backgroundColor = ilo_dark_blue;
      const border = `1px solid ${ilo_dark_blue}`;
      return { ...provided, backgroundColor, border };
    },
    clearIndicator: (provided) => {
      const borderTop = `1px solid ${ilo_yellow}`;
      const backgroundColor = ilo_paler_yellow;
      const borderBottom = borderTop;
      const padding = "8px";
      return { ...provided, borderTop, borderBottom, padding, backgroundColor };
    },
    menu: (provided) => {
      const margin = 0;
      const borderRadius = 0;
      const border = "none";
      const backgroundColor = ilo_paler_yellow;
      const boxShadow = "0 1px 4px 0 rgba(0,0,0,0.5)";
      return {
        ...provided,
        margin,
        borderRadius,
        border,
        backgroundColor,
        boxShadow,
      };
    },
    menuList: (provided) => {
      const padding = 0;
      const margin = 0;
      return { ...provided, padding, margin };
    },
    placeholder: (provided) => {
      return { ...provided, color: ilo_dark_blue };
    },
    singleValue: (provided) => {
      return { ...provided, color: ilo_dark_blue };
    },
    option: (provided, state) => {
      const backgroundColor = state.isFocused
        ? ilo_dark_blue
        : ilo_paler_yellow;
      const color = state.isFocused ? "#ffffff" : ilo_dark_blue;
      const cursor = "pointer";
      return { ...provided, backgroundColor, cursor, color };
    },
  };

  return (
    <MenuWrapper>
      <Label>
        {intl.formatMessage({ id: "speakerRecordings.menu.selectSpeakers" })}
      </Label>
      <Select
        className="react-select-container"
        isClearable
        isSearchable
        options={options}
        onChange={handleChange}
        styles={styles}
        placeholder={intl.formatMessage({
          id: "speakerRecordings.menu.placeholder",
        })}
        value={setCurrentValue()}
      />
    </MenuWrapper>
  );
}

export default SpeakersMenu;
