import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

const leaderEvents = ["4.1", "5.1"];

export const LeadersContext = React.createContext({
  allLeaders: [],
  currentLeader: {},
  updateLeader: () => {},
});

function Leaders({ children, currentEvent }) {
  const [currentLeader, setLeader] = useState(null);

  function updateLeader(leader) {
    setLeader(leader);
  }

  const {
    allSpeakerJson: { nodes: leaders },
  } = useStaticQuery(graphql`
    query MyQuery {
      allSpeakerJson(filter: { events: { regex: "/(4.1|5.1)/g" } }) {
        nodes {
          id
          name
          events
          recording {
            recording_ar
            recording_de
            recording_es
            recording_en
            recording_fr
            recording_ru
            recording_zh
            recording_floor
          }
        }
      }
    }
  `);

  // Only get leaders who have recordings for the currentEvent
  const allLeaders = leaders.filter(
    (leader) =>
      leader.recording !== null &&
      leader.events.indexOf(currentEvent.id.toString()) !== -1
  );

  // On mount, check for a hash in the url
  // If it finds one, set the speaker. If it's not a found speaker, ignore it
  useEffect(() => {
    if (window && typeof window !== "undefined") {
      const { hash } = window.location;
      if (hash.length > 0) {
        const urlSpeaker = hash.slice(1);
        const speakerFound = allLeaders.find(
          (leader) => leader.id === urlSpeaker
        );
        if (speakerFound) {
          updateLeader(speakerFound);
        }
      }
    }
  }, []); // eslint-disable-line

  // Add the current leader to the url hash if there is one
  useEffect(() => {
    if (window && typeof window !== "undefined") {
      if (currentLeader) {
        window.location.hash = currentLeader.id;
        return;
      }
      // Get rid of the hash if no current speaker is set
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
  }, [currentLeader]);

  return (
    <LeadersContext.Provider
      value={{
        currentLeader,
        updateLeader,
        allLeaders,
        leaderEvents,
      }}
    >
      {children}
    </LeadersContext.Provider>
  );
}

export default Leaders;
