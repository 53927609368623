import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useIntl, navigate } from "gatsby-plugin-intl";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Title from "../components/title";
import Button from "../components/button";
import { color } from "../styled/variables";
import DateSelector from "../components/dateSelector";
import SubSelector from "../components/subSelector";
import DaySpeakers from "../components/daySpeakers";
import PlayerDetails from "../components/playerDetails";
import KeyDocuments from "../components/keyDocuments";
import Player from "../components/player";
import Leaders from "../components/leaders";
import download from "../images/icons/download-icon.svg";
import media from "src/styled/responsive";
import SpeakersMenu from "../components/speakersMenu";

const { ilo_yellow } = color;

const ContentWrapper = styled.div`
  margin: 1.5rem 0;

  ${media.sm`
    margin: 0;
  `}
`;

const StyledRow = styled(Row)`
  padding: 0 2rem;

  ${media.sm`
    padding: 0;
  `}
`;

const StyledSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;

  ${media.sm`
    padding: 0 2rem;
  `}

  ${media.xs`
    padding: 0;
    margin: 1rem 0;
  `}
`;

const SubSelectWrapper = styled.div`
  margin: 3rem 0 4rem;
  width: 100%;

  ${media.sm`
    padding: 0 2rem;
    margin: 2rem 0 3rem;
  `}

  ${media.xs`
    padding: 0;
    margin: 2rem 0 4rem;
  `}
`;

const PlayerWrapper = styled.div`
  width: 100%;
  position: relative;

  ${media.sm`
    padding: 0 2rem;
  `}

  ${media.xs`
    padding: 0;
  `}
`;

const Description = styled.section`
  margin: 1.5rem 0;

  ${media.sm`
    padding: 0 2rem;
  `}

  ${media.xs`
    padding: 0;
  `}
`;

const ConceptNote = styled.div`
  font-size: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  top: 1.3rem;
  position: relative;

  &:before {
    content: "";
    background-image: url(${download});
    width: 1.25rem;
    height: 1.25rem;
    position: relative;
    background-size: contain;
    margin: 2px 12px 0 0;
  }
`;

const StyledButton = styled(Button)`
  ${media.sm`
    display: none;
  `}
`;

function GameDay({ pageContext: { allEvents }, data: { eventJson } }) {
  const currentEvent = eventJson;
  const intl = useIntl();

  function updateEvent(path) {
    return navigate(`/event/${path}`);
  }

  const description = {
    en: "descriptionEn",
    es: "descriptionEs",
    fr: "descriptionFr",
  };

  const documents = {
    en: "documents_en",
    es: "documents_es",
    fr: "documents_fr",
  };

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} />
      <Title gameday />
      <ContentWrapper>
        <Grid>
          <StyledRow between="md">
            <Col xs={12} smOffset={1} sm={10} mdOffset={0} md={6}>
              <StyledSection>
                <DateSelector
                  side="left"
                  title={intl.formatMessage({ id: "events.regional" })}
                  currentEvent={currentEvent}
                  updateEvent={updateEvent}
                  events={allEvents.filter(
                    (event) =>
                      event.region && event.region.toLowerCase() !== "global"
                  )}
                />
                <DateSelector
                  side="right"
                  title={intl.formatMessage({ id: "events.global" })}
                  currentEvent={currentEvent}
                  updateEvent={updateEvent}
                  events={allEvents.filter(
                    (event) =>
                      event.region && event.region.toLowerCase() === "global"
                  )}
                />
              </StyledSection>
            </Col>
            <Col>
              <StyledButton
                skin="blue"
                outline={ilo_yellow}
                shape="polygon"
                onClick={() => navigate("/programme")}
              >
                {intl.formatMessage({
                  id: "teaser.home.fullprogramme",
                })}
              </StyledButton>
            </Col>
          </StyledRow>
          <Leaders currentEvent={currentEvent}>
            <Row>
              <Col xs={12} smOffset={1} sm={10} mdOffset={0} md={6}>
                <SubSelectWrapper>
                  <SubSelector
                    currentEvent={currentEvent}
                    updateEvent={updateEvent}
                    allEvents={allEvents}
                  >
                    <SpeakersMenu />
                  </SubSelector>
                </SubSelectWrapper>
              </Col>
            </Row>
            <Row>
              <Col xs={12} smOffset={1} sm={10} mdOffset={0} md={8}>
                <PlayerDetails currentEvent={currentEvent} />
              </Col>
              <Col smOffset={1} sm={10} mdOffset={0} md={4}>
                <ConceptNote>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatHTMLMessage({
                        id: "teaser.home.document",
                      }),
                    }}
                  />
                </ConceptNote>
              </Col>
            </Row>
            <Row>
              <Col xs={12} smOffset={1} sm={10} mdOffset={0} md={8}>
                <PlayerWrapper>
                  <Player currentEvent={currentEvent} />
                </PlayerWrapper>
              </Col>
              <Col md={4}>
                <KeyDocuments
                  documents={currentEvent[documents[intl.locale]]}
                />
              </Col>
            </Row>
          </Leaders>
          <Row>
            <Col xs={12} smOffset={1} sm={10} mdOffset={0} md={8}>
              <Description>
                <p
                  dangerouslySetInnerHTML={{
                    __html: currentEvent[description[intl.locale]],
                  }}
                />
              </Description>
            </Col>
          </Row>
        </Grid>
      </ContentWrapper>
      {currentEvent.speaker.length > 0 && (
        <DaySpeakers
          eventId={currentEvent.id}
          orderByRank={currentEvent.id === "5.1" ? false : true}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query PageQuery($id: String) {
    eventJson(id: { eq: $id }) {
      id
      status
      titleEn
      titleEs
      titleFr
      shortTitleEn
      shortTitleFr
      shortTitleEs
      descriptionEn
      descriptionEs
      descriptionFr
      recording {
        recording_en
        recording_es
        recording_fr
        recording_ru
        recording_ar
        recording_zh
        recording_de
        recording_floor
      }
      time
      region
      speaker
      duration
      documents_fr {
        docLinkFr
        title
      }
      documents_es {
        docLinkEs
        title
      }
      documents_en {
        docLinkEn
        title
      }
    }
  }
`;

export default GameDay;
